/* Postscript */

.ode-postscript-objects {
  margin-top: 10px;
  font-size: .90em;
}

.ode-postscript-objects ul {
  -webkit-padding-start: 0px;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}

.ode-postscript-objects ul li {
  list-style-type: none;
}

.ode-postscript-objects ul li a {
  color: $light-background-color;
  font-size: .85em
}

.postscript-objects-left {
  width: 50%;
}

.postscript-objects-right {
  float: right;
}

.region-postscript-first, .region-postscript-second, .region-postscript-third {
  margin: 20px 0px;
  //padding-right: 40px;
  //border-right: 1px solid #eee;
}

.ode-postscript-links {
  padding-top: 15px;
  border-top: 1px solid #bbb;
  text-align: center;
  margin-top: 10px;
}

.ode-postscript-social {
  font-size: 1.75em;
  text-align: center;
}

.ode-postscript-credits {
  margin-top: 15px;
}

.ode-postscript-links a, .ode-postscript-social a, .ode-postscript-credits {
  font-size: .85em;
  color: $light-background-color;
}

.ode-postscript-links a:hover, .ode-postscript-social a:hover, .ode-postscript-credits a:hover {
  color: #006bff;
}
