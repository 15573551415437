/* Login bar and user links */

.ode-user-links { 
  padding: 10px 0 5px 0;
  float: right;
  height: 40px;
  color: $header-text-color;
  font-size: .85em;
  margin-right: 25px;
}

.ode-user-links a {
  color: $header-text-color;
}

