
// Test that the _colors.scss file is included

$neon_blue: #006bff;

// Text colors

$highlight-color: #3B3041;  // purple 
$highlight-text-color: #fff;

$base-text-color: #111;
$link-text-color: #3966b1; // bright blue
$white-text-color: #fff;
$selection-text-color: $highlight-text-color;
$selection-background-color: $highlight-color;
$border-color: #ddd;

$background-color: #eee;
$white-background-color: #fff;
$light-background-color: #eee;
$dark-background-color: #333;
$dark-background-text: #fff;

$highlight-color: #3B3041;
$highlight-text-color: #fff;

// ------------------------

$button-color: $link-text-color;
$button-text-color: $highlight-text-color;


// Top header

$header-background-color: #5A6A7F;
$header-text-color: #fff;
$header-border-color: #284062;

$callout-background-color: #f0f8ff; // alice blue 
$callout-border-color: #ddd;

// Buttons

$button-background-color: #3966b1;
$button-text-color: #fff;
$button-hover-color: #006bff;
$button-hover-text: #fff;


$text-color: #111;
$link-color: #336699;

$accent-color: #728CAD;
$accent-text: #eee;


// Flags and icons

$flag-bookmark: green;
$flag-report: red;
$flag-star: #ffd700;
