/* FAQ PAGE */

.ode-last-faq-menu { 
  border: 10px solid red;
}

.view-open-debate-engine-faq .attachment-before { 
  font-size: .90em;
  padding: 7px;
  border: 1px solid #eee;
}

.opendebate-faq-questions-bottom h3 {
  background: #eee;
  padding: 5px;
  width: 95%;
}

.opendebate-faq-questions-bottom h3 a { 
  color: #333;
}

.opendebate-faq-bottom-row h3 {
  background: #fff !important;
}
