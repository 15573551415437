/* Omega Zones */

#zone-menu {
  background: #4a4f58;
}

#zone-content-noborder {
  background: none repeat scroll 0 0 white;
  border-color: #B9BBBE #B9BBBE #B2B2B6;
  border-image: none;
  border-radius: 6px 6px 6px 6px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 1px 3px #B9BBBE;
  padding: 10px;
}

#zone-content {
  background: #fff;
  border: 2px solid #ddd;
  border-top: 0px;
  border-bottom: 0px;
  padding: 20px 15px;
}

#zone-postscript-wrapper {
  background: #2a3547;
  margin-top: 25px;
  color: #ddd;
  //height: 150px;
}

#zone-user-wrapper {
  background-color: #284062;
  //position: fixed;
  //top: 0px;
  left: 0px;
  width: 100%;
  //height: 45px;
  z-index: 100;
}

