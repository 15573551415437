
// Test that the commmon.scss file is included 

/* Sidebar */

.region-sidebar-second-inner, .panels-flexible-region-1-sidebar-inside {
  background: aliceblue;
}


#block-views-bibliography-block-3, #block-views-news-block-1 {
  background: #ddd repeat scroll 0 0 white;
  border-color: #B9BBBE #B9BBBE #B2B2B6;
  border-image: none;
  border-radius: 6px 6px 6px 6px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 1px 3px #B9BBBE;
  padding: 10px;
  margin-bottom: 3px;
}

.callout-box {
  border: 1px solid $callout-border-color;
  padding: 10px;
  background: $callout-background-color;
  height: 335px;
  margin-bottom: 31px;
  margin-top: 15px;
}

.ode-object-banner {
  margin-top: -10px;
  color: #fff;
  font-size: 10px;
  font-weight: 900;
  background: #1d2a3a;
  padding: 10px;
  font-size: 1em;
}

.ode-joyride-start, .ode-action-button {
  padding: 10px;
  color: $button-text-color;
  background-color: $button-color;
  margin: 10px 0px;
  width: 50%;
}

.opendebate-update-message {
  border: 1px solid $header-border-color;
  padding: 5px 15px;
  margin: 10px;
  background-color: $callout-background-color;
  font-size: .9em
}

.ode-blog-masthead {
  border-top: 1px solid #284062;
  margin-top: 5px;
  padding: 5px;
}


span[class^=ode-hover-footnote-text-], span[class^=ode-hover-link-text-] { 
  display: none;
}

a[id^=ode-hover-footnote-link-] sup, a[id^=ode-hover-footnote-link] { 
  font-size: 14px !important;
  vertical-align: super;
  font-weight: bold;
}

#block-views-arguments-block-3 ul { 
  padding-left: 15px;
}

#block-views-arguments-block-3 ul li { 
  list-style-type: disc;
  font-size: .85em;
} 

.page-node-revisions .region-sidebar-second, .page-node-revisions .ode-object-banner, .page-node-revisions .ode-object-menu-container {
  display: none;
}

.hidden-expand-container, div[class^=ode-position-container-] {
  display: none;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

a.hidden-expand-toggle {
  padding-right: 1em;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none!important;
  font-weight: bold;
  font-size: 20px;
}

a.hidden-expand-toggle:hover {
  text-decoration: underline!important;
}

.page-sub-title {
  font-size: 17px;
  font-weight: 900;
  margin-top: 0px;
}

a.ode-object-link {
  text-decoration: none;
  color: #fff !important;
}

.ode-action-links a { 
  font-size: .90em !important;
}

.ode-edit-link, .ode-edit-link a, .ode-report-link, .ode-report-link a, .ode-history-link, .ode-history-link a, .ode-bookmarks, .ode-bookmarks a {
  //font-size: .90em !important;
}

.ode-action-links-container { 
  border-top: 1px solid #ccc;
  padding: 10px 0px 0px 0px;
  margin: 10px 1px 5px;
  width: 50%;
}

.field-name-field-argument-evidence ul li {
  margin: .75em 1em !important;
}

.ode-alpha-nav {
  margin: 10px 20px;
  padding-bottom: 15px;
  border-bottom:1px solid #ddd;
}

.view-bibliography .attachment {
  margin-left: -30px;
}

.field-name-field-keyword-image img { 
  border: 1px solid black;
}

.fa-external-link { 
  font-size: .85em !important;
  padding-right: 1px !important;
}

.collapse-widget {
  display: block;
  float: right;
  padding: 5px;
}

.view-bibliography .ode-object-banner, .view-bibliography .ode-object-menu-container {
  margin-left: -30px;
}

.page-search .search-form {
  padding: 10px 15px;
  border: 1px solid black;
}

.ode-object-title { 
  font-size: 1.3em; 
  font-weight: 900;
  line-height: 1.4em;
}

div[class^=ode-position-container-] { 
  font-size: 1.1em;
  line-height: 1.4em;
}

.ode-position-wrapper .ode-object-title {
  font-size: 1.5em;
} 

.block-apachesolr-search-mlt-001 .content {
  padding-left: 10px;
}

/* HOME PAGE STUFF */

.ode-home-splash {
  height: 265px;
}

.front #zone-preface { 
  margin-top: 10px;
  padding: 10px 15px;
}

.front #zone-content-wrapper { 
  padding-top: 0px !important;
}

/* Taxonomy Page */

div .taxonomy-term { 
  margin-top: 15px;
}

/* Status Block */

.ode-status-list li {
  list-style-type: none;
  margin-left: 1px; 
  font-weight: bold;
}

.ode-status-shuffle .fa-random { 
  font-size: .8em;
  color: #000;
  padding: 0 5px;
}

ul.ode-status-list { 
  margin-left: -15px;
}

.ode-user-links a {
  color: #fff;
}

.ode-user-links a:hover {
  text-decoration: underline;
}

.page-contact .contact-form {
  padding: 5px 15px;
}

.tabs li {
  list-style: none;
  display: inline;
  margin-right: 0.5em;
}

.page-user ul.contextual-links {
  display: none;
}

.opendebate-top-top {
  list-style: none;
  list-style-image: none !important;
  margin-left: -15px;
}

.opendebate-parent-top li {
  list-style-type: none;
  list-style-image: none;
  margin-left: 0px;
}

.opendebate-embed-container { 
  padding: 0px 10px;
} 

body.admin-menu #edit-search-block-form--2 {
  width: 85%;
}

#zone-content-wrapper { 
  padding-top: 10px;  
}

html.js input.form-autocomplete {
  width: 90% !important;
}

.block-superfish .block-title {
  display: none;
}


.view-bibliography li.views-row {
  padding: 10px 5px;
  border-top: 1px solid #eee;
  font-size: .95em;
}

.front .breadcrumb, .front .tabs { display: none; }

.view-bibliography li { list-style-type: none; }

#block-views-news-block-1 { 
  font-size: .85em;
}

.fa, .icon-comments-alt, .icon-book, .icon-quote-left, .icon-sitemap, .icon-file-text-alt, .icon-user, .icon-building { 
  text-decoration: none;
  padding-right: 7px;
  font-size: 1.1em;
}

/* SEARCH BOX */

.region-user-second-inner .container-inline { 
  float: right;
}

#edit-search-block-form--2 {
  padding:5px;
  margin-right:-7px;
  width: 140px;
}

.front #page-title { 
  display: none; 
}

.view-content .item-list ul, .block-apachesolr-search ul { 
  margin-right: 15px; 
}

.view-content .item-list li, .block-apachesolr-search li { 
  list-style: none;
  margin-left: 10px !important;
  border-top: 1px solid #eee;
  padding: 5px !important;
}

.region-sidebar-second .view-content .item-list li { 
  padding: 7px 0 !important;
}

.view-content .item-list li.views-row-first,  .block-apachesolr-search li.first { 
  border-top: 0px;
  padding-top: 2px;
}

#region-sidebar-second, .panels-flexible-region-1-sidebar {
  margin-top: -25px; 
  font-size: .95em;
}

#region-sidebar-second .block-views .block-inner .content { 
  padding: 0px 10px;
}

.region-content-inner { 
  margin-top: 0px; 
}

.field-type-entityreference .field-item { 
  padding: 10px 0px;
  border-top: 1px solid #eee;
  margin-right: 10px;
}
  
#page-title { 
  margin-bottom: 10px;
  margin-top: 0px;
}

.block-homepage-slider-block-1 {
  margin-bottom: -40px;
}

#region-sidebar-second .block-inner, .panels-flexible-region-1-sidebar { 
  background: #fff;
}

#region-sidebar-second .block, .panels-flexible-region-1-sidebar { 
  margin-top:15px;
  border-top: 1px solid #eee;
}
	
.view-open-debate-engine-radioactivity li, .block-apachesolr-search li, .view-Evidence li {
  font-size: .90em;
}

.page-taxonomy .field-name-field-keyword-image { 
  display: none;
}

/* System defined blocks */

.ode-status-block {
  padding: 5px 10px;
}

#block-quicktabs-quicktabs-citations {
  padding: 20px 10px 30px 20px;
}

.view-bibliography { 
  margin: 10px 15px 10px 30px; 
}

.opendebate-slider-title { 
  width: 85%;
  float: right;
} 

.ode-postscript-objects {
  padding: 15px;
}

.ode-postscript-objects li { 
  line-height: 20px;
}

.block-opendebate-logo {
  padding: 10px 0 5px 0px;
}

.contextual-links-wrapper { 
  display: none;
}

