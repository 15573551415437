/* Position */

.ode-position-wrapper {
  margin: 0px 0px 10px 0px;
  padding: 10px;
  border: 1px solid #CCCCCC;
  border-radius: 0px 0px 7px 7px;
  margin-bottom: 10px;
}

.opendebate-position-button { 
  margin: 5px auto;
  border: 1px solid black; 
  padding: 7px;
  width: 65%;
  text-align: center;
}

.opendebate-position-button a { 
  text-decoration: none;
}

.opendebate-position-button a:hover { 
  color: #fff;
  background-color: green;
  text-decoration: none;
}

