.block-mailchimp-lists .content {
  padding: 10px;
  margin-top: -1em;
}

.block-mailchimp-lists label { 
  font-size: .85em;
}

.block-mailchimp-lists input { 
  width: 70%;
}

.block-mailchimp-lists .form-submit { 
  width: 40%;
} 
