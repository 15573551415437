/* Cases */

.ode-case-container {
  border: 1px solid #ddd;
  padding: 10px 20px 50px 20px;
}

.ode-case-content { 
  margin-left: 10px;
}
 
.ode-case-content .field-name-body { 
  margin-bottom: 10px; 
  border-bottom: 1px solid #ddd;
}

.ode-case-title { 
  font-size: 1.3em;
  margin-bottom: 10px;
}

.ode-case-author {
  width: 60%;
  border-top: 1px solid #ddd;
}

