// List nav elements

.node-type-organization #page-title, .node-type-evidence #page-title, .node-type-argument #page-title, .node-type-news #page-title, .node-type-citation #page-title, .node-type-author #page-title { 
  display: none;
}

#opendebate-list-nav {
  margin: 10px 0 5px 0;
  padding: 0 0 30px 10px;
  border-bottom: 1px solid $dark-background-color;
}

#opendebate-list-nav li {
  margin: 0; 
  padding: 0 0 10px 0;
  display: inline;
  list-style-type: none;
}
	
#opendebate-list-nav a:link, #opendebate-list-nav a:visited {
  float: left;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  margin: 0 10px 4px 10px;
  padding-bottom: 10px;
  text-decoration: none;
  color: $dark-background-color;
}

#opendebate-list-nav a.active:link, #opendebate-list-nav a.active:visited, #opendebate-list-nav a:hover {
  border-bottom: 4px solid #000;
  padding-bottom: 2px;
  background: $white-background-color;
  color: $dark-background-color;
}

#opendebate-list-nav a:hover {
  border-bottom: 4px solid #006bff;
}

.opendebate-list-nav-rss a:hover {
  border-bottom: 0px !important;
  color: #006bff;
}

.opendebate-list-desc { 
  font-size: .85em;
  margin: 0 10px 10px 0;
  border-bottom: 1px dotted $dark-background-color;
  padding-bottom: 5px;
}
