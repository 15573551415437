/* News */

.ode-news-keyword-image {
  display: none;
}

.ode-news-container {
  margin: 0px 0px 10px 0px;
  padding: 10px;
  line-height: 1.5em;
  border: 1px solid #CCCCCC;
  border-radius: 0px 0px 7px 7px;
}

.ode-news-top {
  font-weight: bolder;
  padding: 7px;
  margin: 3px 0px 7px 0px;
}

.ode-news-body {
  margin-left: 10px;
  margin-bottom: 5px;
}

.ode-news-top {
  background-color: #ebebeb;
}
