// Test that _evidence.scss is loaded
.evidence-loaded { font-size: 1px; }

/* Evidence */

.ode-evidence-body {
  padding: 10px 20px 10px 5px;
  line-height: 1.5em;
}

.ode-evidence-container {
  margin: 0px 0px 10px 0px;
  border: 1px solid #CCCCCC;
  border-radius: 0px 0px 7px 7px;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.ode-evidence-title a {
  font-family: "Lato", sans-serif;
  text-decoration: none;
  font-size: 1.1em;
}

.ode-evidence-divider {
  border-top: 1px solid black;
  width: 45%;
  margin: 5px 0px;
}

.ode-evidence-bqstart {
  float: left;
  height: 50px;
  margin-top: -30px;
  padding-top: 45px;
  margin-bottom: -45px;
  font-size: 1.6em !important;
  color: #605e5e;
}
