
.view-open-debate-engine-blog .views-row {
  margin-bottom: 35px;
}

.block .view-open-debate-engine-blog .views-row {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.view-keywords-terms div.views-field-field-keyword-image { 
  float: left;
  margin: 0px 10px 10px 0px;
  width: 100%;
}

.view-keywords-terms div.views-field-name {
  font-size: 1.3em;
}

.view-keywords-terms .col-1, .view-keywords-terms .col-2 {
  vertical-align: top;
  padding: 10px;
}

.ode-blog-body {
  padding: 0px 5px 15px 15px;
  line-height: 22px;
}

