
// Typography

body {
  font-family: 'Open Sans', sans-serif;
  background: $background-color;
  color: $base-text-color;
  -webkit-font-smoothing: antialiased;
}

a {
  color: $link-text-color;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h2 {
  font-weight: 300;
  color: $header-text-color;
  margin: 10px 0;
}

h2.block-title {
  background-color: $header-background-color;
  color: $header-text-color;
  padding: 7px 10px;
  font-size: .90em;
  font-weight: 900;
  border-bottom: 4px solid $header-border-color;
  text-transform: uppercase;
}

::-moz-selection {
  background-color: $selection-background-color;
  color: $selection-text-color;
}

/* Works in Safari */
::selection {
  background-color: $selection-background-color;
  color: $selection-text-color;
}

/*

WNYC Font collection / theme

body {
  font: 1rem/1.5 "Open Sans",sans-serif;
  font-weight: 400;
  color: #333;
  font-size: 18px;
  line-height: 1.875rem;
}

a {
  border-bottom: 1px solid rgba(0,0,0,.1);
  color: #0073d5;
  word-wrap: break-word;
  text-decoration: none;
}

*/
