.flag-wrapper .fa {
  color: $base-text-color;
}

.flagged .fa-bookmark, .unflag-action .fa-bookmark, .fa-bookmark {
  color: $flag-bookmark;
}

.flagged .fa-flag, .unflag-action .fa-flag {
  color: $flag-report;
}

.flagged .fa-star, .unflag-action .fa-star {
  color: $flag-star;
}
