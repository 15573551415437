/* Authors */

.ode-author-full {
  margin: 0px 0px 10px 0px;
  padding: 10px;
  line-height: 1.5em;
  border: 1px solid #CCCCCC;
  border-radius: 0px 0px 7px 7px;
}

.node-type-author .field-type-image { 
  margin: 0px 20px 20px 0px;
  float: left; 
}
