/* Help Box Content */

.opendebate-help-block-content {
  padding: 0px 15px;
}

.opendebate-home-help-content {
  padding: 5px 15px;
}

.opendebate-help-block-content a, .opendebate-home-help-block a { 
  //text-decoration: underline;
  font-weight: 600;
}

.opendebate-help-block-content a:hover, .opendebate-home-help-block a:hover {
  text-decoration: underline;
  font-weight: 600;
  background: #006bff;
  color: #fff;
}

.block-opendebate-home-help-block {
  font-size: 1.1em;
  line-height: 1.3em;
  border: 2px solid black !important;
}

.block-opendebate-node-help-block { 
  font-size: .90em;
  margin-top: 2px !important;
  border-top: 0px !important;
}

.ode-help-action-header {
  font-weight: 800;
  padding: 5px 0px; 
  width: 40%;
  border-bottom: 1px solid black;
}

/* Welcome / About This Page block */

.block-opendebate-node-help-block {
  border: 2px solid #333;
  font-size: 1.05em;
  line-height: 1.3em;
}

.home-tour-button {
  text-align: center;
  margin-bottom: 10px;
}

.home-tour-button img {
  border: 1px solid black;
}

.opendebate-home-help-content .ode-joyride-start {
  position: absolute;
  bottom: 25px;
  width: 150px;
  right: 100px;
}
