/* Citations */

.ode-citation-full, .page-search-site .ode-citation-teaser {
  margin: 0px 0px 10px 0px;
  padding: 10px;
  line-height: 1.5em;
  border: 1px solid #CCCCCC;
  border-radius: 0px 0px 7px 7px;
}

.ode-citation-simple-qual {
  border-bottom:1px dotted;
}

#quicktabs-container-quicktabs_citations {
  margin-top: 25px;
} 

