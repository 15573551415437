/* Arguments */

.view-Arguments .attachment-before { 
  margin-top: 10px 20px;
}

.view-id-Arguments .view-display-id-page_4 { 
  font-size: 1.2em !important;
}

.opendebate-arg-tree { 
  padding: 5px 15px;
}

.opendebate-arg-tree ul { 
  margin-left: -20px;
  margin-top: 10px;
}

.opendebate-arg-tree a {
  text-decoration: none;
}

.opendebate-arg-tree ul li { 
  list-style-image: url(/sites/all/modules/open_debate_engine/images/sub-arrow.png);
  padding-bottom: 5px;
}

.opendebate-arg-tree ul ul li { 
  list-style-image: url(/sites/all/modules/open_debate_engine/images/sub-arrow.png);
}

.opendebate-arg-compare-left { 
  width: 90%;
  margin: 0 auto;
}

.opendebate-arg-compare-right {
  width: 90%;
  margin: 0 auto;
}

.ode-argument-divider { 
  padding-top: 15px;
}

.opendebate-arg-tree-parent { 
  list-style: none;
  padding-top: 5px;
  margin-top: 5px;
  border-top: 1px solid #ddd;
}

.ode-argument-wrapper {
  margin: 0px 0px 10px 0px;
  padding: 10px;
  border: 1px solid #CCCCCC;
  border-radius: 0px 0px 7px 7px;
  margin-bottom: 10px;
}

.ode-argument-container {
  padding: 10px;
}

.ode-argument-title a {
  text-decoration: none;
  font-size: 18px;
  color: #000;
}

.ode-argument-body {
  padding: 5px;
  margin-left: 15px;
}

.ode-argument-history {
  margin: 10px;
}

.ode-argument-history-container { 
  border: 1px solid black;
  padding: 5px 30px 50px 30px;;
}

.ode-argument-actions { 
  float: right;
}

.ode-argument-history-nav { 
  margin: 10px 0px;
  font-size: .90em;
}

.ode-argument-diff-container {
  padding: 10px 15px;
}

.ode-argument-details { 
  border: 1px dashed black;
  padding: 10px;
  overflow: hidden;
  margin-top: 15px;
  margin-left: -20px;
  font-size: .85em;
  width: 100%;
}

.ode-argument-details-evidence .item-list li {
  list-style: disc !important;
  padding: 0px !important;
  border: 0px !important;
  margin-top: -5px;
  margin-left: 5px; 
}

.ode-argument-details-evidence { 
  width:50%; 
  float: left;
}

.ode-argument-details-arguments { 
  width: 50%;
  float: left;
}

.ode-argument-supporting { 
  margin-top: 15px; 
}

.ode-argument-keywords { 
  min-height: 20px;
}
