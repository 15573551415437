/* Nice Menus */

ul.nice-menu li a, ul.nice-menu li ul {
  border: 0px;
  text-decoration: none;
  color: #fff !important;
}

ul.nice-menu li ul li {
  background: #284062;
  color: #fff;
}

ul.nice-menu li.over {
  background: #006bff;
}

.admin-menu #zone-user-wrapper {
  margin-top: 25px;
}

.block-nice-menus h2 {
  display: none;
}

ul.nice-menu {
  border-top: 0px;
}

ul.nice-menu li, ul.nice-menu li.menuparent {
  margin: 0px;
  border: 0px;
  padding: 7px 10px;
  background-color: #284062;
  color: #fff;
  background: none;
}

ul.nice-menu li .first {
  margin-top: 13px;
}

ul.nice-menu-down li.menuparent, ul.nice-menu-down li.menuparent:hover {
  //background: #006bff;
}

ul.nice-menu li:hover, ul.nice-menu li .over {
  background-color: #006bff !important;
  color: #fff;
}

.block-nice-menus {
  border-top: 1px solid #3a5172;
}
