// Search

.page-search .block-search { 
  display: none;
}

.block-search { 
  width: 235px;
  padding: 5px;
}

.page-search #edit-search-block-form--2 {
  width: 160px;
}

.page-search .search-form #edit-keys {
  padding: 7px;
  width: 33%;
  font-size: 1.1em;
}

/* Search Box */

#edit-submit, #edit-submit--2 {
  background-color: $button-background-color;
  border: 0;
  padding: 7px;
  color: $button-text-color;
  text-transform: uppercase;
  cursor: pointer;
}

#edit-submit:hover, #edit-submit--2:hover {
  background-color: $button-hover-color;
  border: 0;
  padding: 7px;
  color: $button-hover-text;
  text-transform: uppercase;
}

#edit-search-block-form--2 {
  padding: 5px;
  margin-right: -7px;
  width: 140px;
}
