// Test that _quicktabs.scss is loaded 
.quicktabs-loaded { font-size: 1px; }

/* Quicktabs */

.block-quicktabs { 
  padding: 20px 10px;
  border: 1px solid $light-background-color;
  background: url("/sites/all/themes/opendebate/images/gradient-bg.png") top center no-repeat;
  background-size: 130%;
}

.block-quicktabs .block-title {
  display: none;
}

.block-quicktabs {
  margin-top: 20px;
}
